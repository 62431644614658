import React from "react";
import { Container } from "../components/shared/Container";
import { Heading } from "../components/shared/Heading";
import { Inner } from "../components/shared/Inner";

interface IndexPageProps {
  location: {
    pathname: string;
  };
}

export default ({ location }: IndexPageProps) => {
  return (
    <Container pathName={location.pathname}>
      <Inner height="100%">
        <Heading title="Page not found!" subtitle={"404 Error"} />
      </Inner>
    </Container>
  );
};
